<template>
  <div class="full-height">
    <div class="center-container py-auto">
      <div>
        <img src="@/assets/images/logo/medask.png" class="mr-1" width="400px" />
      </div>

      <div class="py-1">
        <h1
          class="text-colorGreen font-weight-bolder text-center p-0 m-0"
          style="letter-spacing: 18px"
        >
          COMING SOON
        </h1>
        <div class="d-flex align-items-center py-1">
          <b-img
            src="@/assets/images/comingsoon/whatsapp.png"
            class="mr-50"
            width="50"
          />
          <h1
            class="text-colorGreen font-weight-bolder text-center"
            style="letter-spacing: 7px"
          >
            +92 319 6048487
          </h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.full-height {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100vh !important; /* Full viewport height */
  margin: 0; /* Reset any default margin */
}

.center-container {
  text-align: center; /* Center text within the container */
  /* Optional: add some padding for better appearance */
  padding: 20px;
}
</style>
